

import JSZip from 'jszip' //压缩文件插件
import FileSaver from 'file-saver'
import {
  downloadZip
} from "@/api/cityresearch";
export const download = {

  computed: {
    notice: {
      get() {
        return this.$store.state.notice
      },
      set(v) {
        this.$store.commit('notice', v)
      }
    },
    noticetitle: {
      get() {
        return this.$store.state.noticetitle
      },
      set(v) {
        this.$store.commit('noticetitle', v)
      }
    }
  },
  methods: {

    //服务器下载
    download(params) {


      let _this = this;
      _this.noticetitle = '正在下载中....';
      _this.notice = true;
      downloadZip(params).then((res) => {
        const showPay = true
        if (showPay) {
          //弹出框生成二维码 支付流程

        

        } else {
          //下载流程
        }

        if (res.code === 1) {

          window.location.href = res.data;
          _this.notice = false;
        }



      });
    },

    /**文件打包
     * arrImages:文件list:[{fileUrl:文件url,renameFileName:文件名}]
     * filename 压缩包名
     * */
    filesToRar(arrImages, filename) {
      let _this = this;
      let zip = new JSZip();
      let cache = {};
      let promises = [];
      _this.noticetitle = '正在加载压缩文件';
      _this.notice = true;



      for (let item of arrImages) {
        const promise = _this.getImgArrayBuffer(item.fileUrl).then(data => {
          // 下载文件, 并存成ArrayBuffer对象(blob)
          zip.file(item.renameFileName, data, {
            binary: true
          }); // 逐个添加文件
          cache[item.renameFileName] = data;
        });
        promises.push(promise);
      }

      Promise.all(promises).then(() => {
        zip.generateAsync({
          type: "blob"
        }).then(content => {
          _this.noticetitle = '正在压缩';
          // 生成二进制流
          FileSaver.saveAs(content, filename); // 利用file-saver保存文件  自定义文件名

          _this.noticetitle = '压缩完成,下载中,请耐心等待...';
          setTimeout(() => {

            _this.notice = false
          }, 2000);
        });
      }).catch(res => {
        _this.$message.error('文件压缩失败');
      });
    },
    //获取文件blob
    getImgArrayBuffer(url) {
      let _this = this;
      return new Promise((resolve, reject) => {
        //通过请求获取文件blob格式
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", url, true);
        xmlhttp.responseType = "blob";
        xmlhttp.onload = function () {
          if (this.status == 200) {
            resolve(this.response);
          } else {
            reject(this.status);
          }
        }
        xmlhttp.send();
      });

    }
  }
}